jQuery(document).ready(function ($) {

	$(function () {
	  $('[data-toggle="tooltip"]').tooltip()
	})

	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});

	$('a.level').each(function (i, o) {
		var c = $(o).html().replace('kukac', String.fromCharCode(64));
		c = c.replace(RegExp(/pont/g), String.fromCharCode(46));
		$(o).attr('href', 'mai' + 'lt' + 'o:' + c).html(c);
	});

	$(document).on('submit', 'form.ajax', function (e) {
		e.preventDefault();
		var $this = $(this);
		$(this).ajaxSubmit({
			method: $this.data('method') || $this.attr('method') || 'POST',
			success: function (resp) {
				cbs.callbackHandler($this.data('callback'), resp, $this);
			}
		});
	});

	var cbs = {
		callbackHandler: function (cb, resp, $elem) {
			$.each((cb || '').split(',') || [], function (i, c) {
				if (c && $.isFunction(cbs[c])) {
					cbs[c](resp, $elem);
				}
			});
		},
		kapcsolat: function (resp, $elem) {
			if (resp.status == 'success') {
				$elem.find('input, textarea').each(function () {
					$(this).val('').html('');
				});
				var $modal = $('#kapcsolat-modal');
				$modal.find('.modal-body').html(resp.body)
					.end().find('.modal-title').html(resp.title);
				$modal.modal('show');
			} else {
				$elem.find('.has-error').each(function () {
					$(this).removeClass('has-error').find('.help-block').remove();
				});
				$.each(resp, function (key, value) {
					if (Array.isArray(value)) {
						$elem.find('#' + key).parents('.form-group').addClass('has-error')
							.append('<span class="help-block">' + value.join(', ') + '</span>');
					}
				});
			}
		}
	};


	$('#figyelmeztetes-modal').modal('show');

	$('.fooldal-video-lejatszo-kep').on('click', function () {
		$('.fooldal-video-lejatszo-kep').css('visibility', 'hidden');
		$('#bgvid').css('visibility', 'visible');
		$('#bgvid').addClass('lathato');
		$('#bgvid')[0].play();
	});

	$('.bal-oldal li').click(function (event) {
		$alul = $(this).children("ul");
		console.log($(this));
		$alul.toggle();
		if ($alul.length > 0) {
			return false;

		}
		event.stopPropagation();
	});

	$('.almenu a.active').each(function (event) {
		$(this).parents('.almenu').show();
	});

	$('.szoveg table').each(function () {
		$(this).wrap("<div class='table-responsive'></div>");

	});
});

$(function() {
    $('a[href$=".pdf"], a[href^="http"]:not(.custom-link)').prop('target', '_blank');
});
